<template>
  <div class="container px-0">
    <div class="restaurant">
      <v-row class="product-big-container">
        <div>
          <v-col cols="12" class="">
            <button @click="createItem" class="btn-add-compound">
              Добавить состав
            </button>
          </v-col>
        </div>
        <v-col cols="12" class="title-container-tabs">
          <template>
            <v-data-table
              :headers="headers"
              :items="filteredCompound"
              :footer-props="footerProps"
              hide-default-footer
            >
              <template v-slot:top>
                <v-dialog v-model="dialogCreate" max-width="537px">
                  <v-card>
                    <v-card-title class="modal-title">
                      Состав
                      <v-spacer></v-spacer>
                      <v-btn icon @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-col cols="12" class="py-0 px-0">
                        <div class="col-content px-0 pt-3 pb-0">
                          <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                            <p class="col-title">Название</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                            <textarea
                              style="height: 44px"
                              class="col-description"
                              v-model="nameRU"
                              ref="nameRUTextarea"
                              :placeholder="
                                isnameRUFocused ? '' : textareaPlaceholder
                              "
                              @focus="isnameRUFocused = true"
                              @blur="isnameRUFocused = false"
                            ></textarea>
                          </v-col>
                        </div>
                      </v-col>

                      <div class="horizontal-line"></div>
                      <v-col cols="12" class="py-0 px-0">
                        <div class="col-content px-0 pt-3 pb-0">
                          <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                            <p class="col-title">Название EN</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                            <textarea
                              style="height: 44px"
                              class="col-description"
                              v-model="nameEN"
                              ref="nameENTextarea"
                              :placeholder="
                                isnameENFocused ? '' : textareaPlaceholder
                              "
                              @focus="isnameENFocused = true"
                              @blur="isnameENFocused = false"
                            ></textarea>
                          </v-col>
                        </div>
                      </v-col>

                      <div class="horizontal-line"></div>
                      <v-col cols="12" class="py-0 px-0">
                        <div class="col-content px-0 pt-3 pb-0">
                          <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                            <p class="col-title">Описание</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                            <textarea
                              style="height: 44px"
                              class="col-description"
                              v-model="descriptionRU"
                              ref="descriptionRUTextarea"
                              :placeholder="
                                isdescriptionRUFocused
                                  ? ''
                                  : textareaPlaceholder
                              "
                              @focus="isdescriptionRUFocused = true"
                              @blur="isdescriptionRUFocused = false"
                            ></textarea>
                          </v-col>
                        </div>
                      </v-col>
                      <div class="horizontal-line"></div>
                      <v-col cols="12" class="py-0 px-0">
                        <div class="col-content px-0 pt-3 pb-0">
                          <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                            <p class="col-title">Описание EN</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                            <textarea
                              style="height: 44px"
                              class="col-description"
                              v-model="descriptionEN"
                              ref="descriptionENTextarea"
                              :placeholder="
                                isdescriptionENFocused
                                  ? ''
                                  : textareaPlaceholder
                              "
                              @focus="isdescriptionENFocused = true"
                              @blur="isdescriptionENFocused = false"
                            ></textarea>
                          </v-col>
                        </div>
                      </v-col>
                      <div class="horizontal-line"></div>
                      <v-col cols="12" class="py-0 px-0">
                        <div class="col-content-sortType" style="height: 63px">
                          <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                            <p class="col-title">Тип отображения</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col cols="3" class="py-0">
                            <v-select
                              v-model="selectedOptionSortType"
                              :items="sortType"
                              class=""
                              variant="underlined"
                            ></v-select>
                          </v-col>
                        </div>
                      </v-col>
                      <div class="horizontal-line"></div>
                      <v-col cols="12" class="py-0 px-0">
                        <div class="col-content px-0 pt-3 pb-0">
                          <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                            <p class="col-title">Номер в списке</p>
                          </v-col>
                          <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                            <span class="red-dot">*</span>
                          </v-col>
                          <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                            <textarea
                              style="height: 44px"
                              class="col-description"
                              v-model="orderNum"
                              ref="orderNumTextarea"
                              :placeholder="
                                isorderNumFocused ? '' : textareaPlaceholder
                              "
                              @focus="isorderNumFocused = true"
                              @blur="isorderNumFocused = false"
                              @input="handleInput"
                            ></textarea>
                          </v-col>
                        </div>
                      </v-col>
                      <div class="horizontal-line"></div>
                    </v-card-text>
                    <div class="btn-container">
                      <div
                        class="delete-btn"
                        v-if="isEditing"
                        @click="deleteItemConfirm"
                      >
                        Удалить
                      </div>
                      <div class="cancel-btn" text @click="closeCreate">
                        Отмена
                      </div>

                      <div class="save-btn" text @click="handleButtonClick">
                        Сохранить
                      </div>
                    </div>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="780px">
                  <v-card>
                    <v-card-title class="warning-title"
                      >Вы точно хотите удалить данный состав из продукта?
                      <v-btn icon @click="closeDelete">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-actions class="warning-margin">
                      <div class="cancel-btn" text @click="closeDelete">
                        Отмена
                      </div>
                      <div class="del-btn" text @click="deleteItemConfirm">
                        Удалить
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-snackbar
                  v-model="snackbar"
                  :timeout="3000"
                  class="btn-contaiener"
                >
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div
                      class="snackbar-btn"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Закрыть
                    </div>
                  </template>
                </v-snackbar>
              </template>
              <template v-slot:item="{ item, index }">
                <tr>
                  <td @click="getProductCompound(item)">
                    {{
                      item.name.length > 10
                        ? item.name.slice(0, 40) + "..."
                        : item.name
                    }}
                  </td>

                  <td @click="getProductCompound(item)">
                    {{
                      item.description.length > 10
                        ? item.description.slice(0, 40) + "..."
                        : item.description
                    }}
                  </td>
                  <td @click="getProductCompound(item)">
                    {{ item.sortOrder || " " }}
                  </td>
                  <td @click="getProductCompound(item)">
                    {{ item.createdOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getProductCompound(item)">
                    {{ item.modifiedOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getProductCompound(item)">
                    {{ getTypeTranslation(item.type) }}
                  </td>
                  <td @click="getProductCompound(item)">
                    {{ item.createdBy.fullName }}
                    <!-- <span v-for="(localization, index) in item.localizations" :key="index">
                        {{ localization.locale }}: {{ localization.name }}<br>
                    </span> -->
                  </td>

                  <td style="text-align: right">
                    <v-icon medium @click="toggleActionMenu(index)"
                      >mdi-dots-vertical</v-icon
                    >
                    <div
                      class="ActionMenu"
                      v-if="showActionMenu[index]"
                      ref="ActionMenu"
                    >
                      <div
                        @click="getProductCompound(item)"
                        class="ActionMenu-item-1"
                      >
                        Редактировать
                      </div>
                      <div class="divider-modal"></div>
                      <div @click="deleteItem(item)" class="ActionMenu-item-2">
                        Удалить
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <div v-if="showNoDataMessage">Нет подходящих записей.</div>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>
              <template v-slot:footer>
                <div class="v-data-footer">
                  <div class="v-data-footer__select">
                    Кол-во элементов на странице:
                    <v-select
                      class="custom-select"
                      v-model="itemsPerPage"
                      :items="footerProps.itemsPerPageOptions"
                      @change="changeItemsPerPage"
                      hide-details
                    ></v-select>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ (currentPage - 1) * itemsPerPage + 1 }} -
                    {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                    {{ totalItems }}
                  </div>
                  <div class="v-data-footer__icons-before">
                    <button
                      type="button"
                      :disabled="currentPage === 1"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === 1 }"
                      aria-label="Предыдущая страница"
                      @click="goToPreviousPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-left theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                  <div class="v-data-footer__icons-after">
                    <button
                      type="button"
                      :disabled="currentPage === totalPages"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === totalPages }"
                      aria-label="Следующая страница"
                      @click="goToNextPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-right theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
}
.warning-margin {
  margin-top: 32px;
  margin-bottom: 10px;
}
.warning-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  justify-content: space-between;
  padding: 31px 14px 10px 31px !important;
}
.col-content-sortType {
  display: flex;
  align-items: center;
}
.btn-add-compound {
  border-radius: 8px;
  background: var(--03, #bcb648);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  color: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.test-texarea {
  resize: none; /* Запретить изменение размеров */
  overflow-y: hidden; /* Скрыть вертикальную полосу прокрутки */
  min-height: 65px; /* Минимальная высота */
  height: auto; /* Автоматическая высота */
}
.delete-block-container {
  text-align: right;
  margin-top: 15px;
}
.delete-block {
  color: #e04444;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.row-container {
  display: flex;
  align-items: center;
}
.btn-special-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  margin-bottom: 32px;
}
.btn-special {
  color: #444ae0;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}
.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}
.btn-save-container {
  text-align: end;
}
.btn-save {
  border-radius: 8px;
  background: var(--03, #bcb648);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  color: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btn-photo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.photo-description {
  display: flex;
  align-items: center;
}
.information-text-3 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 30px;
}
.information-text-2 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 43px;
}
.information-text-1 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.input-select-width {
  width: 30%;
}
/* .product-big-container {
   margin-left: 39px;
  margin-right: 30px; 
} */
.col-content-energy {
  display: flex;
  align-items: flex-start;
}
.weight-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
.month-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ):hover {
  background: #f2f2f680;
}

::v-deep .v-list-item__title {
  align-self: center;

  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
::v-deep .v-application .primary--text {
  color: #bcb648 !important;
  caret-color: #bcb648 !important;
}
::v-deep .v-select__selection--comma {
  margin: 7px 4px 7px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
::v-deep .v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--06, #fff);
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: none !important;
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.select-text {
  padding-left: 5px;
}
.select-container {
  margin: 0 0 0 auto;
  width: 63px;
}
.textarea-pt {
  padding-top: 21px;
}
.work-margin-bot {
  margin-bottom: 50px;
}
.col-title-work {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.special-border:focus {
  border-radius: 3px;
  border: 2px solid var(--03, black);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.special-border {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.time-end {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}
.end-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.start-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.work-img-margin {
  margin-right: 21px;
}
.work-1 {
  text-align: end;
}
.dot-work {
  margin-left: 69px;
}
.work-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-container {
  margin-top: 17px;
}

.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .top-panel .container {
    width: 100%;
    padding: 0px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
  text-decoration: none;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.full-text-input-3 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input-2 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input,
.full-text-input-2,
.full-text-input-3,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.full-text-input,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}
.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 227px;
}
.margin-logo-divider {
  margin-bottom: 48px;
}
.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-file-upload {
  cursor: pointer;
}
.img-container-flex {
  display: flex;
  justify-content: space-between;
}
.uploaded-image-logo {
  /* max-width: 392px; 
  max-height: 382px;  */
  max-width: 100%;
  max-height: 100%;
}
.uploaded-image-container {
  width: auto;
  height: auto;
  border-radius: 10px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  padding: 14px;
}

.work-margin-img {
  margin-bottom: 15px;
}
.work-img {
  width: 22px;
  height: 22px;
}
.col-img {
  flex-direction: column-reverse;
  display: flex;
}
.work-margin {
  padding-bottom: 15px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
}
.horizontal-line-1 {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
}
.tel-pointer {
  cursor: pointer;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}
.col-content {
  display: flex;
  align-items: flex-start;
}
.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  min-height: 20px;
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}
.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
}
::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-data-table__wrapper > table > thead > tr > th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid #848484;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up,
::v-deep .v-data-table-header__icon.mdi-arrow-down {
  display: inline-block;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-up */
  font-family: "Material Design Icons";
}

/* Значок сортировки по убыванию */
::v-deep .v-data-table-header__icon.mdi-arrow-up.rev::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down.rev::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-down */
  font-family: "Material Design Icons";
}
.ActionMenu {
  position: absolute;
  /* bottom: -110px;
  left: -180px; */
  width: 156px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  right: 2%;
}
.ActionMenu-item-1:hover,
.ActionMenu-item-2:hover {
  background-color: rgba(
    242,
    242,
    246,
    0.5
  ); /* изменяем цвет текста при наведении мыши */
}

.ActionMenu-item-1 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-top-left-radius: 10px; /* Закругляем нижний левый угол */
  border-top-right-radius: 10px; /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}
.ActionMenu-item-2 {
  border-bottom-left-radius: 10px; /* Закругляем нижний левый угол */
  border-bottom-right-radius: 10px; /* Закругляем нижний правый угол */
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-align: start;
  cursor: pointer;
}
::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}
.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-right: 25px;
}
.btn-container {
  margin-bottom: 46px;
  align-items: center;
  text-align: end;
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
}
.delete-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-right: 35px;
}

.del-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #b80900);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  height: 30px;
  /* min-height: 20px; */
  width: 100%;
  /* margin-left: 18px; */
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}
</style>
<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
export default {
  name: "Compound",
  directives: { mask },
  props: {
    requestData: {},
    responseData: {},
  },
  data() {
    return {
      isEditing: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
      showActionMenu: [],
      showNoDataMessage: false,
      blocks: [],
      selectedImage: null,
      message: "",
      isnameRUFocused: false,
      isnameENFocused: false,
      isdescriptionRUFocused: false,
      isdescriptionENFocused: false,
      isorderNumFocused: false,
      orderNum: "",
      bannerName: "",
      nameRU: "",
      nameEN: "",
      descriptionRU: "",
      descriptionEN: "",
      newProduct: {},
      snackbar: false,
      productbase: "",
      dialogCreate: false,
      dialogDelete: false,
      currentState: "Active",
      statusTranslations: {
        Active: "Активный",
        Inactive: "Неактивный",
      },
      headers: [
        {
          text: "Название",
          align: "start",
          value: "name",
        },
        { text: "Описание", value: "name" },
        { text: "Номер в списке", value: "sortOrder" },
        { text: "Дата создания", value: "name" },
        { text: "Дата изменения", value: "name" },
        { text: "Тип", value: "name" },
        { text: "Пользователь", value: "name" },

        { text: "Действия", align: "end", value: "actions", sortable: false },
      ],
      compounds: [],
      compoundUpdate: [],
      currentItem: "",
      compound: {},
      selectedOptionSortType: "",
      sortType: [
        {
          value: "Outer",
          text: "Вверх",
        },
        {
          value: "Inner",
          text: "Низ",
        },
      ],
      typeTranslations: {
        Outer: "Верх",
        Inner: "Низ",
      },
    };
  },
  created() {
    // this.additionalComponentRU = this.responseData.additionalComponent;
    // this.additionalComponentEN = this.responseData.localizations[0].additionalComponent;
    this.initialize();
  },
  methods: {
    handleInput() {
      // Оставить только цифры
      this.orderNum = this.orderNum.replace(/\D/g, "");
    },
    getTypeTranslation(type) {
      return this.typeTranslations[type] || type;
    },
    createNewProductComponent() {
      (this.newProduct.name = this.nameRU),
        (this.newProduct.description = this.descriptionRU),
        (this.newProduct.type = this.selectedOptionSortType),
        (this.newProduct.localizations = [
          {
            locale: "en",
            name: this.nameEN,
            description: this.descriptionEN,
          },
        ]),
        (this.newProduct.productId = this.$route.params.productId);
      (this.newProduct.sortOrder = this.orderNum),
        this.$http
          .post("/api/ProductComponent", this.newProduct)
          .then(() => {
            this.newProduct = {
              name: "",
              sortOrder: "",
              description: "",
              type: "",
              productId: "",
              localizations: [
                {
                  locale: "",
                  name: "",
                  description: "",
                },
              ],
            };
            this.snackbar = true;
            this.message = "Состав успешно добавлен";
            this.initialize();
            this.closeCreate();
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
            this.message = "Ошибка добавления состава";
          });
    },
    addBlock() {
      this.blocks.push({
        title: "",
        titleEN: "",
        description: "",
        descriptionEN: "",
        // ...другие поля
      });
    },
    removeBlock(index) {
      this.blocks.splice(index, 1);
    },
    handleButtonClick() {
      if (this.isEditing) {
        // Выполните метод для редактирования
        this.editCompoundComponent();
      } else {
        // Выполните метод для создания нового приложения
        this.createNewProductComponent();
      }
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    adjustTextareaHeightBlock(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },
    // selectedOptionState(option) {
    //   this.selectedOptionState = option;
    // },
    getStatusTranslation(status) {
      return this.statusTranslations[status] || status;
    },
    handleClickOutsideActionMenu(event) {
      const ActionButton = this.$refs.ActionButton;
      const ActionMenu = this.$refs.ActionMenu;

      if (
        ActionButton &&
        ActionMenu &&
        !ActionButton.contains(event.target) &&
        !ActionMenu.contains(event.target)
      ) {
        this.showActionMenu = false;
      }
    },
    toggleActionMenu(index) {
      // Закрыть все другие меню
      for (let i = 0; i < this.showActionMenu.length; i++) {
        if (i !== index) {
          this.$set(this.showActionMenu, i, false);
        }
      }
      // Переключить меню для выбранного элемента
      this.$set(this.showActionMenu, index, !this.showActionMenu[index]);

      // Добавляем обработчик события на документе для закрытия меню при клике вне меню
      if (this.showActionMenu[index]) {
        document.addEventListener("click", this.closeActionMenu);
      } else {
        document.removeEventListener("click", this.closeActionMenu);
      }
    },
    closeActionMenu(event, index) {
      // Проверяем, был ли клик вне меню
      if (
        this.$refs.ActionMenu &&
        !this.$refs.ActionMenu.contains(event.target)
      ) {
        // Закрываем меню
        this.showActionMenu[index] = false;
        document.removeEventListener("click", (event) =>
          this.closeActionMenu(event, index)
        );
      }
    },
    getEnglishRoleTranslation(role) {
      for (const key in this.roleTranslations) {
        if (Object.prototype.hasOwnProperty.call(this.roleTranslations, key)) {
          if (this.roleTranslations[key] === role) {
            return key;
          }
        }
      }
      return role;
    },
    closeDialog() {
      this.dialogCreate = false;
      this.nameRU = "";
      this.nameEN = "";
      this.selectedOptionSortType = "";
      this.descriptionEN = "";
      this.descriptionRU = "";
      this.selectedOptionState = "";
    },
    selectOptionPackage(sortType) {
      this.selectedOptionSortType = sortType;
    },
    // handleButtonClick() {
    //   if (this.isEditing) {
    //     // Выполните метод для редактирования
    //     this.editBanner();
    //   } else {
    //     // Выполните метод для создания нового приложения
    //     this.createNewBanner();
    //   }
    // },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        this.initialize();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.initialize();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1;
      this.initialize();
    },
    initialize() {
      this.$http
        .put("/api/ProductComponent/PagedList", {
          pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          filters: [
            {
              property: "ProductId",
              operator: "eq",
              value: this.$route.params.productId,
            },
          ],
          sorting: {
            property: "SortOrder",
            isDescending: false,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.compounds = response.data;
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
          if (this.totalItems === 0) {
            this.showNoDataMessage = true;
          } else {
            this.showNoDataMessage = false;
          }
        })
        .catch((error) => (this.error = error));
    },

    deleteItem(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.editedIndex = this.filteredCompound.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentItem = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.$http
        .delete(`/api/ProductComponent/${this.currentItem}`)
        .then(() => {
          this.dialogCreate = false;
          // this.staffs.items.splice(this.currentItem, 1);
          this.initialize();
          this.closeDelete();

          // Здесь мы устанавливаем флаг showSnackbar в true,
          // чтобы уведомление показалось после завершения удаления
          this.snackbar = true;
          this.message = "Состав успешно удален";
        })
        .catch((error) => {
          alert(error);
          this.snackbar = true;
          this.message = "Ошибка удаления состава";
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
      this.isEditing = false;
      this.nameRU = "";
      this.nameEN = "";
      this.orderNum = "";
      this.selectedOptionSortType = "";
      this.descriptionRU = "";
      this.descriptionEN = "";
      // this.getEmployee();
    },

    closeCreate() {
      this.dialogCreate = false;
      this.nameRU = "";
      this.nameEN = "";
      this.orderNum = "";
      this.selectedOptionSortType = "";
      this.descriptionRU = "";
      this.descriptionEN = "";
      this.selectedOptionState = "";
      this.isEditing = false;
    },

    getProductCompound(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.createItem();
      this.isEditing = true;
      this.editedIndex = this.filteredCompound.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentItem = item.id;
      this.$http
        .get(`/api/ProductComponent/${item.id}`)
        .then((response) => {
          this.compound = response.data;
          this.nameRU = this.compound.name;
          this.descriptionRU = this.compound.description;
          this.selectedOptionSortType = this.compound.type;
          this.orderNum = this.compound.sortOrder;

          if (
            this.compound.localizations.length &&
            this.compound.localizations[0]
          ) {
            this.nameEN = this.compound.localizations[0].name;
            this.descriptionEN = this.compound.localizations[0].description;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    editCompoundComponent() {
      this.compound.name = this.nameRU;
      this.compound.description = this.descriptionRU;
      this.compound.type = this.selectedOptionSortType;
      this.compound.sortOrder = this.orderNum;
      (this.compound.localizations = [
        {
          name: this.nameEN,
          description: this.descriptionEN,
          locale: "en",
        },
      ]),
        this.$http
          .put(`/api/ProductComponent`, this.compound, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            this.initialize();
            this.dialogCreate = false;
            this.snackbar = true;
            this.message = "Состав успешно изменен";
          })
          .catch((error) => {
            alert(error);
            this.snackbar = true;
            this.message = "Ошибка редактирования состава";
          });
    },

    // editItem(item) {
    //   this.$router.push({ path: `/admin/application/${item.id}` });
    // },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },

    handleClickOutside(event) {
      const inputButton = this.$refs.inputButton;
      const inputMenu = this.$refs.inputMenu;

      if (
        inputButton &&
        inputMenu &&
        !inputButton.contains(event.target) &&
        !inputMenu.contains(event.target)
      ) {
        this.showInputMenu = false;
      }
    },

    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
  },

  watch: {
    nameRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameRUTextarea");
      });
    },
    nameEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("nameENTextarea");
      });
    },
    descriptionRU() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionRUTextarea");
      });
    },
    descriptionEN() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("descriptionENTextarea");
      });
    },
  },
  computed: {
    footerProps() {
      return {
        itemsPerPageOptions: [1, 5, 7, 10], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
    filteredCompound() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        console.log(this.selectedType);
        return this.compounds.items
          .filter
          // (item) => item.rolesTest === this.selectedType
          ();
      } else {
        return this.compounds.items;
      }
    },

    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },

    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
