<template>
  <div class="container px-0">
    <div class="restaurant">
      <v-row class="product-big-container">
        <v-col cols="12" class="title-container-tabs">
          <template>
            <div>
              <v-row>
                <v-col cols="4">
                  <div class="content-container">
                    <v-col cols="12">
                      <h2 class="information-title pb-0">
                        Пищевая ценность в 100 г продукта
                      </h2>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Белки</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="protein"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Жиры</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="fats"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">В том числе насыщщенные</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="SaturatedFats"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Углеводы</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="Carbohydrates"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">В том числе сахар</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="Sugar"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Клечатка</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="Cellulose"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                    <div class="horizontal-line"></div>
                    <v-col cols="12" class="py-0">
                      <div class="col-content" style="height: 63px">
                        <v-col cols="6" class="col-paddings-r-l px-0 py-0">
                          <p class="col-title">Соль</p>
                        </v-col>

                        <div
                          class="col-paddings-r-l input-select-width px-0 py-0"
                        >
                          <input
                            v-model="Salt"
                            placeholder="---"
                            class="weight-text"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.,]/g, '')"
                          />
                        </div>
                        <div
                          class="col-paddings-r-l px-0 py-0 select-container"
                        >
                          <v-select
                            class=""
                            v-model="selectedOption"
                            :items="options"
                            item-value="value"
                            item-text="text"
                            variant="underlined"
                          >
                          </v-select>
                        </div>
                      </div>
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="8">
                  <div class="btn-save-container">
                    <button class="btn-save" @click="handleButtonClick">
                      Сохранить
                    </button>
                  </div>
                </v-col>
              </v-row>
              <v-snackbar
                v-model="snackbar"
                :timeout="3000"
                class="btn-contaiener"
              >
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <div
                    class="snackbar-btn"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Закрыть
                  </div>
                </template>
              </v-snackbar>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}
.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}
.btn-save-container {
  text-align: end;
}
.btn-save {
  border-radius: 8px;
  background: var(--03, #bcb648);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  color: var(--06, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btn-photo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.photo-description {
  display: flex;
  align-items: center;
}
.information-text-3 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 30px;
}
.information-text-2 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 43px;
}
.information-text-1 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.input-select-width {
  width: 30%;
}
.product-big-container {
  /* margin-left: 39px;
  margin-right: 30px; */
}
.col-content-energy {
  display: flex;
  align-items: flex-start;
}
.weight-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
.month-text {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background: #f2f2f680;
}

::v-deep .v-list-item__title {
  align-self: center;

  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
::v-deep .v-application .primary--text {
  color: #bcb648 !important;
  caret-color: #bcb648 !important;
}
::v-deep .v-select__selection--comma {
  margin: 7px 4px 7px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
::v-deep .v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--06, #fff);
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: none !important;
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.select-text {
  padding-left: 5px;
}
.select-container {
  margin: 0 0 0 auto;
  width: 63px;
}
.textarea-pt {
  padding-top: 19px;
}
.work-margin-bot {
  margin-bottom: 50px;
}
.col-title-work {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.special-border:focus {
  border-radius: 3px;
  border: 2px solid var(--03, black);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.special-border {
  border-radius: 3px;
  border: 1px solid var(--03, #c1c1c1);
  background: var(--white, #fff);
  width: 295px;
  height: 37px;
  padding-left: 10px;
}
.time-end {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}

.time-start {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
}
.end-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.start-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.work-img-margin {
  margin-right: 21px;
}
.work-1 {
  text-align: end;
}
.dot-work {
  margin-left: 69px;
}
.work-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-container {
  margin-top: 17px;
}

.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .top-panel .container {
    width: 100%;
    padding: 0px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  } */
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  margin-left: 37px;
  cursor: pointer;
  text-decoration: none;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.full-text-input-3 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input-2 {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}

.full-text-input {
  white-space: normal; /* Позволяет тексту переноситься */
  overflow-y: hidden;
  resize: none;

  /* min-height: 38px; Минимальная высота для начала */
}
.full-text-input,
.full-text-input-2,
.full-text-input-3,
::placeholder {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.full-text-input,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.requisites-text-container {
  display: flex !important;
  flex-direction: row;
  /* justify-content: space-evenly; */
  align-items: baseline;
}
.requisites-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 227px;
}
.margin-logo-divider {
  margin-bottom: 48px;
}
.logo-dot-3 {
  margin-right: 13px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot-2 {
  margin-right: 16px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-dot {
  margin-right: 69px;
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-file-upload {
  cursor: pointer;
}
.img-container-flex {
  display: flex;
  justify-content: space-between;
}
.uploaded-image-logo {
  /* max-width: 392px; 
  max-height: 382px;  */
  max-width: 100%;
  max-height: 100%;
}
.uploaded-image-container {
  width: auto;
  height: auto;
  border-radius: 10px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  padding: 14px;
}

.work-margin-img {
  margin-bottom: 15px;
}
.work-img {
  width: 22px;
  height: 22px;
}
.col-img {
  flex-direction: column-reverse;
  display: flex;
}
.work-margin {
  padding-bottom: 15px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
  margin-right: 12px;
  margin-left: 12px;
}
.tel-pointer {
  cursor: pointer;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}

.col-paddings-t-b {
  padding-top: 0px;
  padding-bottom: 0px;
}
.col-content {
  display: flex;
  align-items: center;
}
.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  min-height: 20px; /* Минимальная высота для textarea */
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  word-wrap: break-word;
}
.information-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.content-container {
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  background: var(--white, #fff);
  margin-top: 0px;
}
</style>
<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
export default {
  name: "Summary",
  directives: { mask },
  props: {
    requestData: {},
    responseData: {},
  },
  data() {
    return {
      selectedImage: null,
      message: "",
      protein: "",
      fats: "",
      SaturatedFats: "",
      Carbohydrates: "",
      Sugar: "",
      Cellulose: "",
      Salt: "",
      uom: "",
      selectedOption: "0",
      options: [
        {
          value: "0",
          text: "Г",
        },
      ],

      newProduct: {},
      nutritional: [],
      snackbar: false,
    };
  },
  created() {
    this.protein = this.responseData.nutritionalValues[0].value;
    this.fats = this.responseData.nutritionalValues[1].value;
    this.SaturatedFats = this.responseData.nutritionalValues[2].value;
    this.Carbohydrates = this.responseData.nutritionalValues[3].value;
    this.Sugar = this.responseData.nutritionalValues[4].value;
    this.Cellulose = this.responseData.nutritionalValues[5].value;
    this.Salt = this.responseData.nutritionalValues[6].value;
    this.nutritional = this.responseData.nutritionalValues;
  },
  methods: {
    handleButtonClick() {
      this.editNutrition();
      // тут апдейт нужен
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
    hidePlaceholder(inputName) {
      if (inputName === "legalAdress") {
        this.isLegalAdressFocused = true;
      } else if (inputName === "actualAdress") {
        this.isActualAdressFocused = true;
      }
    },

    handleInput() {
      this.bestBeforeMonths = this.bestBeforeMonths.replace(/[^0-9.,]/g, "");
    },

    selectOption(option) {
      this.selectedOption = option;
      console.log(`Вы выбрали опцию: ${option}`);
    },
    editNutrition() {
      console.log(this.nutritional);
      this.nutritional[0].value = this.protein;
      this.nutritional[1].value = this.fats;
      this.nutritional[2].value = this.SaturatedFats;
      this.nutritional[3].value = this.Carbohydrates;
      this.nutritional[4].value = this.Sugar;
      this.nutritional[5].value = this.Cellulose;
      this.nutritional[6].value = this.Salt;

      this.$http
        .put(`/api/ProductNutrition/UpdateList`, this.nutritional, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.snackbar = true;
          this.message = "Пищевая ценность успешно изменена";
        })
        .catch((error) => {
          console.log(error);

          this.snackbar = true;

          if (error.response && error.response.status === 400) {
            this.message = "Заполните Пищевую ценность";
          } else {
            this.message = "Ошибка при сохранении Пищевой ценности";
          }
        });
    },
  },

  watch: {},
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },

    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
